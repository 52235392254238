<template>
  <b-card-code title="جدول طلبات التعيين" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- filter -->
        <b-form-group
          label="فلتر"
          label-cols-sm="1"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="title"
              type="search"
              placeholder="فلتر"
            />
            <b-input-group-append>
              <b-button @click="resetSearch()">
                الغاء الفلتر
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      ref="table"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :items="items"
      :fields="fields"
    >
      <template #cell(link)="link">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-info"
          @click="view(link.item.file)"
        >
          <feather-icon icon="FileTextIcon" class="mr-50" />
          <span class="align-middle">عرض السيرة الذاتية</span>
        </b-button>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          @change="check()"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
        @change="check()"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <b-modal
      id="model-e-w"
      ok-title="حفظ التعديلات"
      centered
      size="lg"
      title="تعديل الطلب"
      @show="checkModel"
      @hidden="rEF"
      @ok="uM"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <label for="input-valid1">الاسم الكامل:</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.ar_name"
              :state="editform.ar_name.length > 0"
              placeholder="الاسم الكامل"
            />
          </b-col>

          <b-col md="12">
            <label for="input-invalid1">الاسم الكامل بالانكليزي:</label>
            <b-form-input
              id="input-invalid1"
              v-model="editform.en_name"
              :state="editform.en_name.length > 0"
              placeholder="الاسم الكامل بالانكليزي"
            />
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">القسم</label>
              <v-select
                v-model="editform.department_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="departments"
                placeholder="اختر القسم"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-show="stages.length>0">
            <b-form-group>
              <label for="basic-password"> المرحلة</label>
              <v-select
                v-model="editform.stage_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="stages"
                placeholder="اختر المرحلة"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">Select teacher</label>
              <v-select
                v-model="editform.teacher_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="teachers"
                placeholder="Select teacher"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal
      id="model-a-w"
      ok-title="Accept"
      centered
      size="lg"
      title="Add Apply"
      @show="checkModel"
      @hidden="rF"
      @ok="aNM"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <label for="input-valid1">Title by arabic:</label>
            <b-form-input
              id="input-valid1"
              v-model="form.ar_name"
              :state="form.ar_name.length > 0"
              placeholder="title by arabic"
            />
          </b-col>

          <b-col md="12">
            <label for="input-invalid1">Title by english:</label>
            <b-form-input
              id="input-invalid1"
              v-model="form.en_name"
              :state="form.en_name.length > 0"
              placeholder="title by english"
            />
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">Select Department</label>
              <v-select
                v-model="form.department_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="departments"
                placeholder="select department"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-show="stages.length>0">
            <b-form-group>
              <label for="basic-password">Select Stage</label>
              <v-select
                v-model="form.stage_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="stages"
                placeholder="Select Stage"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">Select Teacher</label>
              <v-select
                v-model="form.teacher_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="teachers"
                placeholder="Select Teacher"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      title="Delete User"
      @ok="dM"
      ok-title="Accept"
    >
      <b-card-text>
        هل تريد حذف هذه الحقل  " {{ this.editform.ar_name }} "
        ?
      </b-card-text>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import store from "@/store";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,
  BForm,
  BFormRow,
  BCol,
  VBModal,
  BBadge,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,BProgress,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BForm,
    vSelect,
    BAvatar,
    BBadge,
    BFormRow,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    togglePasswordVisibility,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BModal,BProgress,
    VBModal,
    Ripple,
    BCol,
    BFormCheckbox,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
  },
  data() {
    return {
      teacher_id: "",
      perPage: 3,
      pageOptions: [3, 5, 10, 100],
      totalRows: 1,
      currentPage: 0,
      department:"",
      title:"",
      specialty:"",
      filter: null,
      filterOn: [],
      departments: [],
      teachers: [],
      stages: [],
      fields: [
        {
          key: "name",
          label: "الاسم الكامل ",
        },
        {
          key: "phone",
          label: "الهاتف ",
        },
        {
          key: "graguation",
          label: "الشهادة ",
        },
        {
          key: "general_specialty",
          label: "التخصص العام ",
        },
        {
          key: "accurate_specialty",
          label: "التخصص الدقيق ",
        },
        {
          key: "link",
          label: "السيرة الذاتية ",
        },
      ],
      items: [],
      form: {
        en_name: "",
        ar_name: "",
        stage_id: "",
        department_id: "",
        teacher_id: "",
      },
      editform: {
        en_name: "",
        ar_name: "",
        stage_id: "",
        department_id: "",
        teacher_id: "",
        id: null,
      },
      selected: [],
      selected2: [],
    };
  },
  watch: {
    specialty: function (val) {
      this.gS();
    },
    title: function (val) {
      this.gS();
    },
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.ID(), this.gR(), this.gSM(),this.gSM2()
  },
  methods: {
    async gSM() {
      await this.axios
        .get(`since/deparment?take=${this.perPage}&skip=${
            this.currentPage - 1
          }&select=true`)
        .then((res) => {
          this.departments = [];
          this.departments = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM1() {
      this.stages = []
      this.editform.stage_id==""
      console.log(this.form.department_id)
      if(! Number.isInteger(this.editform.department_id)){
        this.editform.department_id = this.editform.department_id["id"]

      }
      await this.axios
        .get(`stage/clint/department/${this.editform.department_id}?select=true`)
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM3() {
      this.stages = []
      this.form.stage_id==""
      if(! Number.isInteger(this.form.department_id)){
        this.form.department_id = this.form.department_id["id"]

      }
      await this.axios
        .get(`stage/clint/department/${this.form.department_id}?select=true`)
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM2() {
      this.teachers = []
      this.editform.teacher_id==""
      await this.axios
        .get(`teachers-name`)
        .then((res) => {
          this.teachers = [];
          this.teachers = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    checkModel() {
      this.selected = [];
      if (this.editform.is_sustainabilty == 1) {
        this.selected.push("is_sustainabilty");
      }
      if (this.editform.is_energy == 1) {
        this.selected.push("is_energy");
      }
    },
    resetCheckboxes() {
      this.selected = [];
    },
    async uM() {
      if(! Number.isInteger(this.editform.department_id)){
        this.editform.department_id = this.editform.department_id["id"]
      }
      if(! Number.isInteger(this.editform.stage_id)){
        this.editform.stage_id = this.editform.stage_id["id"]
      }
      if(! Number.isInteger(this.editform.teacher_id)){
        this.editform.teacher_id = this.editform.teacher_id["id"]

      }
      await this.axios
        .post(`lecture/${this.editform.id}`, this.editform)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    view(path) {
      console.log(path);
      window.open(`${filesUrl}/${path}`, "_blank");
    },
    async aNM() {
      if(! Number.isInteger(this.form.department_id)){
        this.form.department_id = this.form.department_id["id"]
      }
      if(! Number.isInteger(this.form.stage_id)){
        this.form.stage_id = this.form.stage_id["id"]
      }
      if(! Number.isInteger(this.form.teacher_id)){
        this.form.teacher_id = this.form.teacher_id["id"]
      }
      await this.axios
        .post(`lecture`, this.form)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "update Success",
              "Your model is added successfully"
            );
          }
          this.rF();
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    rF() {
      this.form.en_name = "",
      this.form.ar_name = "",
      this.form.stage_id = "",
      this.form.teacher_id = "",
      this.form.department_id = ""
    },
    rEF() {
      this.editform.en_name = "",
      this.editform.ar_name = "",
      this.editform.stage_id = "",
      this.editform.department_id = "",
      this.editform.teacher_id = "",
      this.editform.id = ""
    },
    async dM() {
      await this.axios
        .delete(`lecture/${this.editform.id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
    eRM(item) {
      console.log(item)
      this.editform.en_name = item.en_name;
      this.editform.ar_name = item.ar_name;
      this.editform.stage_id = item.stage_id;
      this.editform.department_id = item.department_id;
      this.editform.teacher_id = item.teacher_id;
      this.editform.id = item.id;
    },
    dRM(item) {
      this.editform.name = item.name;
      this.editform.id = item.id;
    },
    check() {
      setTimeout(() => this.gR(), 1000);
    },
    ID() {
      this.teacher_id = localStorage.getItem("NisourTeacherId");
    },
    async gS() {
      await this.axios
        .get(
          `job/application?take=${this.perPage}&skip=${this.currentPage - 1}&title=${this.title}&specialty=${this.specialty}`
        )
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gR() {
      await this.axios
        .get(`job/application?take=${this.perPage}&skip=${this.currentPage - 1}`)
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    resetSearch(){
      this.title = '',
      this.specialty = ''
      this.gR()
    }
  },
};
</script>
